import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { UserConstants } from '@api/models/user.constants';
import { SignInFormsTranslations } from '@bussiness/i18n/auth.i18n';
import {
	PlcTypedFormControl,
	PlcValidationErrorType,
} from '@components/models/foms.models';
import { PlcForm } from '@core/interfaces/form.interface';
import { PlcTranslatable } from '@core/interfaces/translatable.interface';
import { PlcCommonValidators } from '@core/validators/common.validators';
import {
	PlcUserAsyncValidators,
	PlcUserValidators,
} from '@core/validators/user.validators';

export interface SignInFormsValue {
	name: string;
	email: string;
	password: string;
	repeatPassword: string;
}

@Injectable()
export class SignInForms
	implements PlcForm, PlcTranslatable<SignInFormsTranslations>
{
	public nameControl: PlcTypedFormControl<string>;
	public emailControl: PlcTypedFormControl<string>;
	public passwordControl: PlcTypedFormControl<string>;
	public repeatPasswordControl: PlcTypedFormControl<string>;

	public i18n: SignInFormsTranslations;

	public get isValid(): boolean {
		return this._form.valid;
	}

	public get value(): SignInFormsValue {
		return this._form.value;
	}

	private _form: UntypedFormGroup;

	constructor() {
		this.setTranslations();

		this.nameControl = new PlcTypedFormControl('', {
			validators: [
				Validators.required,
				Validators.minLength(UserConstants.nameMinLen),
				Validators.maxLength(UserConstants.nameMaxLen),
			],
			asyncValidators: [PlcUserAsyncValidators.nameExists()],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.nameRequired,
					interpolatedParams: { name: this.i18n.name },
				},
				{
					error: PlcValidationErrorType.minLen,
					message: this.i18n.nameMinlen,
					interpolatedParams: {
						name: this.i18n.name,
						min: UserConstants.nameMinLen,
					},
				},
				{
					error: PlcValidationErrorType.maxLen,
					message: this.i18n.nameMaxlen,
					interpolatedParams: {
						name: this.i18n.name,
						max: UserConstants.nameMaxLen,
					},
				},
				{
					error: PlcValidationErrorType.nameExists,
					message: this.i18n.nameExists,
					interpolatedParams: { name: this.i18n.name },
				},
			],
		});

		this.emailControl = new PlcTypedFormControl('', {
			validators: [Validators.required, PlcUserValidators.email],
			asyncValidators: [PlcUserAsyncValidators.emailExists(false)],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.emailRequired,
					interpolatedParams: { name: this.i18n.email },
				},
				{
					error: PlcValidationErrorType.emailNotValid,
					message: this.i18n.emailValid,
					interpolatedParams: { name: this.i18n.email },
				},
				{
					error: PlcValidationErrorType.emailExists,
					message: this.i18n.emailExists,
					interpolatedParams: { name: this.i18n.email },
				},
			],
		});

		this.passwordControl = new PlcTypedFormControl('', {
			validators: [
				Validators.required,
				Validators.minLength(UserConstants.passMinLen),
			],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.passRequired,
					interpolatedParams: { name: this.i18n.pass },
				},
				{
					error: PlcValidationErrorType.minLen,
					message: this.i18n.passMinlen,
					interpolatedParams: {
						name: this.i18n.pass,
						min: UserConstants.passMinLen,
					},
				},
			],
		});

		this.repeatPasswordControl = new PlcTypedFormControl('', {
			validators: [
				Validators.required,
				Validators.minLength(UserConstants.passMinLen),
			],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.passRequired,
					interpolatedParams: { name: this.i18n.pass },
				},
				{
					error: PlcValidationErrorType.minLen,
					message: this.i18n.passMinlen,
					interpolatedParams: {
						name: this.i18n.pass,
						min: UserConstants.passMinLen,
					},
				},
				{
					error: PlcValidationErrorType.unmatched,
					message: this.i18n.passUnmatch,
					interpolatedParams: { name: this.i18n.pass },
				},
			],
		});

		this._form = new UntypedFormGroup(
			{
				name: this.nameControl,
				email: this.emailControl,
				password: this.passwordControl,
				repeatPassword: this.repeatPasswordControl,
			},
			{
				validators: PlcCommonValidators.valuesAreEquals(
					'password',
					'repeatPassword',
				),
			},
		);
	}

	setTranslations(): void {
		this.i18n = {
			name: 'forms.fields.name-prefix',
			email: 'forms.fields.email-prefix',
			pass: 'forms.fields.password-prefix',
			nameRequired: 'forms.validations.required',
			nameMinlen: 'forms.validations.minlength',
			nameMaxlen: 'forms.validations.maxlength',
			nameExists: 'forms.validations.already-exists',
			emailRequired: 'forms.validations.required',
			emailValid: 'forms.validations.valid-format',
			emailExists: 'forms.validations.already-exists',
			passRequired: 'forms.validations.required',
			passMinlen: 'forms.validations.minlength',
			passUnmatch: 'forms.validations.unmatch',
		};
	}

	reset(): void {
		this._form.reset();
	}
}
