import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { UserConstants } from '@api/models/user.constants';
import { ResetPasswordFormsTranslations } from '@bussiness/i18n/auth.i18n';
import {
	PlcTypedFormControl,
	PlcValidationErrorType,
} from '@components/models/foms.models';
import { PlcForm } from '@core/interfaces/form.interface';
import { PlcTranslatable } from '@core/interfaces/translatable.interface';
import { PlcCommonValidators } from '@core/validators/common.validators';
import {
	PlcUserAsyncValidators,
	PlcUserValidators,
} from '@core/validators/user.validators';

@Injectable()
export class ResetPasswordForms
	implements PlcForm, PlcTranslatable<ResetPasswordFormsTranslations>
{
	public i18n: ResetPasswordFormsTranslations;

	public emailControl: PlcTypedFormControl<string>;
	public codeControl: PlcTypedFormControl<string>;
	public passControl: PlcTypedFormControl<string>;
	public repeatPassControl: PlcTypedFormControl<string>;

	public get passAreValid(): boolean {
		return this._passForm.valid;
	}

	private _passForm: UntypedFormGroup;

	constructor() {
		this.setTranslations();

		this.emailControl = new PlcTypedFormControl('', {
			validators: [Validators.required, PlcUserValidators.email],
			asyncValidators: [PlcUserAsyncValidators.emailExists(true)],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.emailRequired,
					interpolatedParams: { name: this.i18n.email },
				},
				{
					error: PlcValidationErrorType.emailNotValid,
					message: this.i18n.emailValid,
					interpolatedParams: { name: this.i18n.email },
				},
				{
					error: PlcValidationErrorType.emailDoesNotExists,
					message: this.i18n.emailDoesNotExist,
					interpolatedParams: { name: this.i18n.email },
				},
			],
		});

		this.codeControl = new PlcTypedFormControl('', {
			validators: [
				Validators.required,
				Validators.minLength(UserConstants.resetPassPinCodeLen),
			],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.codeRequired,
					interpolatedParams: { name: this.i18n.code },
				},
				{
					error: PlcValidationErrorType.minLen,
					message: this.i18n.codeMinlen,
					interpolatedParams: {
						name: this.i18n.code,
						min: UserConstants.resetPassPinCodeLen,
					},
				},
			],
		});

		this.passControl = new PlcTypedFormControl('', {
			validators: [
				Validators.required,
				Validators.minLength(UserConstants.passMinLen),
			],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.passRequired,
					interpolatedParams: { name: this.i18n.pass },
				},
				{
					error: PlcValidationErrorType.minLen,
					message: this.i18n.passMinlen,
					interpolatedParams: {
						name: this.i18n.pass,
						min: UserConstants.passMinLen,
					},
				},
			],
		});
		this.repeatPassControl = new PlcTypedFormControl('', {
			validators: [
				Validators.required,
				Validators.minLength(UserConstants.passMinLen),
			],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.passRequired,
					interpolatedParams: { name: this.i18n.pass },
				},
				{
					error: PlcValidationErrorType.minLen,
					message: this.i18n.passMinlen,
					interpolatedParams: {
						name: this.i18n.pass,
						min: UserConstants.passMinLen,
					},
				},
				{
					error: PlcValidationErrorType.unmatched,
					message: this.i18n.passUnmatch,
					interpolatedParams: { name: this.i18n.pass },
				},
			],
		});

		this._passForm = new UntypedFormGroup(
			{
				password: this.passControl,
				repeatPassword: this.repeatPassControl,
			},
			{
				validators: PlcCommonValidators.valuesAreEquals(
					'password',
					'repeatPassword',
				),
			},
		);
	}

	setTranslations(): void {
		this.i18n = {
			email: 'forms.fields.email-prefix',
			code: 'forms.fields.code-prefix',
			pass: 'forms.fields.password-prefix',
			emailRequired: 'forms.validations.required',
			emailValid: 'forms.validations.valid-format',
			emailDoesNotExist: 'forms.validations.does-not-exists',
			codeRequired: 'forms.validations.required',
			codeMinlen: 'forms.validations.minlength',
			passRequired: 'forms.validations.required',
			passMinlen: 'forms.validations.minlength',
			passUnmatch: 'forms.validations.unmatch',
		};
	}

	reset(): void {
		this.emailControl.reset();
		this.codeControl.reset();
		this._passForm.reset();
	}

	// public runNameValidations(): void {
	// 	const timeout = setTimeout(() => {
	// 		if (this.emailControl.value)
	// 			this.emailControl.updateValueAndValidity();
	// 		clearTimeout(timeout);
	// 	}, 0);
	// }
}
