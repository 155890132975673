import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { UserConstants } from '@api/models/user.constants';
import { FastLoginFormsTranslations } from '@bussiness/i18n/auth.i18n';
import {
	PlcTypedFormControl,
	PlcValidationErrorType,
} from '@components/models/foms.models';
import { PlcTranslatable } from '@core/interfaces/translatable.interface';
import { PlcUserAsyncValidators } from '@core/validators/user.validators';

@Injectable()
export class FastLoginForms
	implements PlcTranslatable<FastLoginFormsTranslations>
{
	public i18n: FastLoginFormsTranslations;
	public readonly nameControl: PlcTypedFormControl<string>;

	public get nameValue(): string {
		return this.nameControl.value;
	}

	public get isValid(): boolean {
		return this._form.valid;
	}

	private _form: UntypedFormGroup;

	constructor() {
		this.setTranslations();

		this.nameControl = new PlcTypedFormControl('', {
			validators: [
				Validators.required,
				Validators.minLength(UserConstants.nameMinLen),
				Validators.maxLength(UserConstants.nameMaxLen),
			],
			asyncValidators: [PlcUserAsyncValidators.nameExists()],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.nameRequired,
					interpolatedParams: { name: this.i18n.name },
				},
				{
					error: PlcValidationErrorType.minLen,
					message: this.i18n.nameMinlen,
					interpolatedParams: {
						name: this.i18n.name,
						min: UserConstants.nameMinLen,
					},
				},
				{
					error: PlcValidationErrorType.maxLen,
					message: this.i18n.nameMaxlen,
					interpolatedParams: {
						name: this.i18n.name,
						max: UserConstants.nameMaxLen,
					},
				},
				{
					error: PlcValidationErrorType.nameExists,
					message: this.i18n.nameExists,
					interpolatedParams: { name: this.i18n.name },
				},
			],
		});
		this._form = new UntypedFormGroup({
			name: this.nameControl,
		});
	}
	setTranslations(): void {
		this.i18n = {
			name: 'forms.fields.name-prefix',
			nameRequired: 'forms.validations.required',
			nameMinlen: 'forms.validations.minlength',
			nameMaxlen: 'forms.validations.maxlength',
			nameExists: 'forms.validations.already-exists',
		};
	}

	public runNameValidators(): void {
		const timeout = setTimeout(() => {
			this.nameControl.updateValueAndValidity();
			clearTimeout(timeout);
		}, 0);
	}
}
