import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { UserConstants } from '@api/models/user.constants';
import { LogInFormsTranslations } from '@bussiness/i18n/auth.i18n';
import {
	PlcTypedFormControl,
	PlcValidationErrorType,
} from '@components/models/foms.models';
import { PlcForm } from '@core/interfaces/form.interface';
import { PlcTranslatable } from '@core/interfaces/translatable.interface';

export interface LogInFormValue {
	name: string;
	password: string;
}

@Injectable()
export class LoginForms
	implements PlcForm, PlcTranslatable<LogInFormsTranslations>
{
	public i18n: LogInFormsTranslations;

	public readonly nameControl: PlcTypedFormControl<string>;
	public readonly passwordControl: PlcTypedFormControl<string>;

	public get isValid(): boolean {
		return this._form.valid;
	}

	public get value(): LogInFormValue {
		return this._form.value;
	}

	private _form: UntypedFormGroup;

	constructor() {
		this.setTranslations();

		this.nameControl = new PlcTypedFormControl('', {
			validators: [
				Validators.required,
				Validators.minLength(UserConstants.nameMinLen),
				Validators.maxLength(UserConstants.nameMaxLen),
			],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.nameRequired,
					interpolatedParams: { name: this.i18n.name },
				},
				{
					error: PlcValidationErrorType.minLen,
					message: this.i18n.nameMinlen,
					interpolatedParams: {
						name: this.i18n.name,
						min: UserConstants.nameMinLen,
					},
				},
				{
					error: PlcValidationErrorType.maxLen,
					message: this.i18n.nameMaxlen,
					interpolatedParams: {
						name: this.i18n.name,
						max: UserConstants.nameMaxLen,
					},
				},
			],
		});

		this.passwordControl = new PlcTypedFormControl('', {
			validators: [
				Validators.required,
				Validators.minLength(UserConstants.passMinLen),
			],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.passRequired,
					interpolatedParams: { name: this.i18n.password },
				},
				{
					error: PlcValidationErrorType.minLen,
					message: this.i18n.passMinlen,
					interpolatedParams: {
						name: this.i18n.password,
						min: UserConstants.passMinLen,
					},
				},
			],
		});

		this._form = new UntypedFormGroup({
			name: this.nameControl,
			password: this.passwordControl,
		});
	}

	setTranslations(): void {
		this.i18n = {
			name: 'forms.fields.name-prefix',
			password: 'forms.fields.password-prefix',
			nameRequired: 'forms.validations.required',
			nameMinlen: 'forms.validations.minlength',
			nameMaxlen: 'forms.validations.maxlength',
			passRequired: 'forms.validations.required',
			passMinlen: 'forms.validations.minlength',
		};
	}

	reset(): void {
		this._form.reset();
	}
}
